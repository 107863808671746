<script setup lang="ts">
import type { Feedback } from '#types/master';
import {
  ref,
  reactive,
  useToast,
  useAuth,
  watch,
  useAirpazCookie,
  useI18n,
  useTracker,
  useRoute,
  useRouteBaseName,
} from '#imports';

const { t } = useI18n();
const auth = useAuth();
const toast = useToast();

const showFeedbackModal = ref(false);
const modalState = ref<0 | 1 | 2>(0);
const feedbackCookies = useAirpazCookie('feedback_submit', { maxAge: 86400 });
const getRouteBaseName = useRouteBaseName();
const route = useRoute();

const feedbackValue = reactive<Feedback>({
  rating: 0,
  comment: '',
  concern: '',
  specification: '',
  email: '',
});

function toggleFeedbackModal() {
  if (feedbackCookies.value) {
    toast.show({
      type: 'error',
      message: t('csat.maximumdaylimit'),
    });

    window?.scrollTo({ top: 0, behavior: 'instant' });
  } else showFeedbackModal.value = true;
}

function onBack() {
  if (modalState.value > 0) modalState.value -= 1;
}

function submitFeedback() {
  const csat: Feedback = {
    rating: feedbackValue.rating,
    comment: feedbackValue.comment,
    concern: feedbackValue.concern,
    specification: feedbackValue.specification,
    email: feedbackValue.email,
  };
  useTracker().jitsuEvent('feedback', {
    screen_name: getRouteBaseName(route) as string,
    csat,
  });

  toast.show({
    type: 'success',
    message: t('csat.successfulcsat'),
  });

  // @ts-expect-error
  window?.scrollTo({ top: 0, behavior: 'instant' });

  if (feedbackValue.rating > 3) feedbackCookies.value = 'true';
  showFeedbackModal.value = false;
  resetFeedbackState();
}

function resetFeedbackState() {
  modalState.value = 0;
  feedbackValue.rating = 0;
  feedbackValue.comment = '';
  feedbackValue.concern = '';
  feedbackValue.specification = '';
  feedbackValue.email = '';
}

function getRatingValue(value: any) {
  feedbackValue.rating = value.rating;
  if (feedbackValue.rating <= 3) modalState.value = 1;
  else {
    feedbackValue.comment = value.comment;
    if (auth.loggedIn && auth.user) feedbackValue.email = auth.user.email;
    submitFeedback();
  }
}

function getFeedbackValue(value: any) {
  feedbackValue.concern = value.concern;
  feedbackValue.specification = value.specification;
  feedbackValue.comment = value.comment;

  if (auth.loggedIn && auth.user) {
    feedbackValue.email = auth.user.email;
    submitFeedback();
  } else modalState.value = 2;
}

function getEmailValue(value: string) {
  feedbackValue.email = value;
  submitFeedback();
}

watch(showFeedbackModal, () => {
  if (!showFeedbackModal.value) resetFeedbackState();
});
</script>
<template>
  <div
    id="csat_container"
    class="container"
    data-testid="yourFeedback-content-container"
  >
    <div class="text-center">
      <h2 class="font-bold text-extra">{{ $t('csat.feedbackcsattitle') }}</h2>
      <p class="text-gray-dark text-medium mt-20">{{ $t('csat.feedbackcsatdesc') }}</p>
      <div class="w-full flex flex-col items-center mt-20 text-danger">
        <ApzButton
          type="primary"
          variant="outline"
          class="py-[16px] px-[30.5px]"
          data-testid="share-feedback-button"
          @click="toggleFeedbackModal"
        >
          {{ $t('csat.feedbackcsatbutton') }}
        </ApzButton>
      </div>
    </div>
    <ApzModal v-model="showFeedbackModal">
      <div v-if="modalState === 0">
        <NuxtImg
          src="/img/airpaz_feedback_animation.gif"
          format="png"
          alt="Airpaz logo"
          class="ml-20 mt-20"
          width="81"
          height="81"
          fit="scale-down"
          sizes="lg:50vw"
        />

        <div class="px-10 py-20">
          <FeedbackRatingForm @rating-value="getRatingValue" />
        </div>
      </div>
      <div
        v-if="modalState === 1"
        class="p-20"
      >
        <div
          class="flex items-center"
          @click="onBack"
        >
          <ApzIcon
            :icon="['far', 'chevron-left']"
            fixed-width
            class="text-base"
          />
          <span>{{ $t('global.goback') }}</span>
        </div>
        <FeedbackConcernForm @feedback-value="getFeedbackValue" />
      </div>
      <div
        v-else-if="modalState === 2"
        class="px-20 py-20"
      >
        <FeedbackEmailForm @email-value="getEmailValue" />
      </div>
    </ApzModal>
  </div>
</template>
